@media (max-width: 899px) {
    #riskalert .alert-left img {
        width: 70%;
    }
    #riskalert .risk-center img {
       padding-left: 40px !important;
    }
  }
  

  .riskalertParentBox{
    /* border: 2px solid #E2E2E2; */
    /* padding: 10px; */
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border: 1px solid #E2E2E2;
    margin-bottom: 30px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .riskalertContentBox{
    background-color: #fff;
    padding: 20px;
  }


  @media  (max-width: 500px) {
    .riskalertParentBox{
      /* border: 2px solid #E2E2E2; */
      /* padding: 10px; */
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border: 1px solid #E2E2E2;
      margin-bottom: 30px;
      box-shadow: 0px 4px 4px 0px #00000040;
      width: 98%;
    }
    #riskalert{
      padding-left: 10px !important;
    }
  }

  @media  (max-width: 400px) {
    .riskalertParentBox{
      /* border: 2px solid #E2E2E2; */
      /* padding: 10px; */
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border: 1px solid #E2E2E2;
      margin-bottom: 30px;
      box-shadow: 0px 4px 4px 0px #00000040;
      width: 95%;
    }
    #riskalert{
      padding-left: 30px !important;
    }
  }