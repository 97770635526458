@media (min-width: 1437px) and (max-width: 2000px) {
  #safedriving .drivingscore-output {
    margin-left: 110px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1436px) {
  #safedriving .drivingscore-output {
    margin-left: 110px !important;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  #safedriving .drivingscore-output {
    margin-left: 100px !important;
  }
}
