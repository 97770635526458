#section1 .background-video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 95.3vh;
  z-index: -1;
}

@media (max-width: 575px) {
  #section1 .homebtn {
    top: 220px !important;
  }
}

@media (min-width: 576px) and (max-width: 2000px) {
  #section1 .homebtn {
    top: 70% !important;
  }
}

#section1 .background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#section1 .content-overlay {
  position: absolute;
  top: 0;
}

#section1 .content-overlay .main {
  height: 90vh;
}

#section1 .content-overlay .main .sub2 {
  padding: 40px;
}

#section1 .content-overlay h1 {
  color: #ffcf24;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}

#section1 .content-overlay h6 {
  color: #ffcf24;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

#section1 .content-overlay h5 {
  color: #fff;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

#section1 .content-overlay .line {
  height: 1px;
  width: 100%;
  background-color: #ffcf24;
  margin-bottom: 20px;
}

#section3 {
  background: linear-gradient(180deg, #100919 0%, #130922 100%);
  margin-top: 7%;
  /* height: 100%; */
}



#section3 .sub1 .content1,
.content2,
.content3 {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}


#section3 .sub1 .content1 .line {
  height: 2px;
  width: 250px;
  background-color: #ffcf24;
  margin-top: 20px;
}

#section3 .sub1 .content2 p {
  /* Apply linear gradient to text */
  background: linear-gradient(180deg, #615AE4 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  /* For Safari/Chrome */
  background-clip: text;
  /* Standard syntax */
  color: transparent;
  /* Hide original text color */

  text-align: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 26px;
  font-weight: 800;
  letter-spacing: 0em;
}


#section3 .sub1 .content3 p {
  color: #ffcf24;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 43px;
}

#section3 .sub2 .content1 .line {
  height: 2px;
  width: 250px;
  background-color: #ffcf24;
  margin-top: 20px;
}

#section3 .sub2 {
  margin-top: 25px;
}

#section3 .sub2 .content1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#section3 .sub2 .content1 img {
  height: 272px;
}

#section3 .sub3 {
  margin-top: 45px;
}

#section3 .sub3 .content1 p {
  color: #fff;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
}



#section3 .sub4 {
  margin: 100px;
  margin-right: 100px;
  margin-bottom: 0;
}

#section3 .sub4 .leftContent {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 26px;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0em;
  color: #FFFFFF;
  text-align: left;
  width: 210px;
}

#section3 .sub4 .more {
  font-family: 'Red Hat Display', sans-serif;
  width: 150px;
  margin-top: 50px;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0em;
  text-align: center;
  background: #615AE4;
  border-radius: 25px;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #FFFFFF
}

#section3 .sub4 .more:hover {
  background: #FCB632;
}

#section3 .sub4 .bgImage {
  position: "absolute";
  bottom: 0;
  left: 0;
  margin-left: 5%;
  margin-top: -10%;
}

#section3 .sub4 .bgImage img {
  width: 690px;
  height: 480px;

}

#section3 .sub4 .line3 {
  height: 1px;
  width: 100%;
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 30px;
}

#section3 .sub4 .head {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.content {
  margin-top: 0px !important;
  line-height: 20px;
}

#section3 .sub4 .content {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

@media (min-width: 1501px) and (max-width: 1600px) {
  #section3 .sub4 .bgImage {
    margin-left: 5%;
    margin-top: -20%;
  }

  #section3 .sub4 .bgImage img {
    width: 650px;
    height: 450px;
  }

  #section3 .sub2 .content1 img {
    height: 255px;
  }
}

@media (min-width: 1401px) and (max-width: 1500px) {
  #section3 .sub4 .bgImage {
    margin-left: 3%;
    margin-top: -15%;
  }

  #section3 .sub4 .bgImage img {
    width: 630px;
    height: 450px;
  }

  #section3 .sub2 .content1 img {
    height: 250px;
  }

  #section3 .sub4 .line3 {
    margin-top: 35px;
    margin-bottom: 25px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  #section3 .sub4 .bgImage {
    margin-left: 2%;
    margin-top: -15%;
  }

  #section3 .sub4 .bgImage img {
    width: 600px;
    height: 430px;
  }

  #section3 .sub2 .content1 img {
    height: 250px;
  }

  #section3 .sub4 .line3 {
    margin-top: 35px;
    margin-bottom: 25px;
  }
}

@media (min-width: 1101px) and (max-width: 1300px) {
  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: -15%;
  }

  #section3 .sub4 .bgImage img {
    width: 500px;
    height: 390px;
  }

  #section3 .sub1 .content2 p {
    font-size: 26px;
    font-weight: 800;
  }

  #section3 .sub2 .content1 img {
    height: 240px;
  }

  #section3 .sub4 .line3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #section3 .sub3 .content1 p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }

  #section3 .sub4 .leftContent {
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    width: 180px;
  }

  #section3 .sub4 .more {
    width: 140px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }

  #section3 .sub4 .content {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (min-width: 901px) and (max-width: 1100px) {
  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: -20%;
  }

  #section3 .sub4 .bgImage img {
    width: 430px;
    height: 320px;
  }

  #section3 .sub1 .content2 p {
    font-size: 26px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 220px;
  }

  #section3 .sub4 .line3 {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
  }

  #section3 .sub4 .leftContent {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    width: 150px;
  }

  #section3 .sub4 .more {
    width: 110px;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  #section3 .sub4 {
    margin: 80px;
    margin-bottom: 0;
  }

  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: -15%;
  }

  #section3 .sub4 .bgImage img {
    width: 310px;
    height: 200px;
  }

  #section3 .sub1 .content2 p {
    font-size: 26px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 180px;
  }

  #section3 .sub4 .line3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  #section3 .sub4 .leftContent {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    width: 120px;
  }

  #section3 .sub4 .more {
    width: 90px;
    margin-top: 10px;
    font-size: 8px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {

  #section3 .sub4 {
    margin: 70px;
    /* margin-right: 50px; */
    margin-bottom: 0;
  }

  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: -15%;
  }

  #section3 .sub4 .bgImage img {
    width: 270px;
    height: 170px;
  }

  #section3 .sub1 .content2 p {
    font-size: 20px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 150px;
  }

  #section3 .sub4 .line3 {
    margin-top: 15px;
    margin-bottom: 8px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  #section3 .sub4 .leftContent {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    width: 110px;
  }

  #section3 .sub4 .more {
    width: 80px;
    margin-top: 8px;
    font-size: 7px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
}

@media (min-width: 400px) and (max-width: 599px) {

  #section3 .sub4 {
    padding-top: 20px;
    /* border-top: 1px solid #fff; */
    margin-top: 10px;
  }

  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: 0%;
  }

  #section3 .sub4 .bgImage img {
    width: 250px;
    height: 150px;
  }

  #section3 .sub1 .content2 p {
    font-size: 20px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 140px;
  }

  #section3 .sub4 .line3 {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  #section3 .sub4 .leftContent {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    justify-content: center;
    width: 110px;
    margin-bottom: 20px;
  }

  #section3 .sub4 .more {
    width: 80px;
    margin-top: 8px;
    font-size: 7px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  
   

  #section3 .sub4 {
    margin: 10px;
    /* border-top: 1px solid #fff; */
    padding-top: 20px;
  }

  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: 0%;
  }

  #section3 .sub4 .bgImage img {
    width: 200px;
    height: 100px;
  }

  #section3 .sub1 .content2 p {
    font-size: 20px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 110px;
  }

  #section3 .sub4 .line3 {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 25px !important;
  }

  #section3 .sub4 .leftContent {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    justify-content: center;
    width: 110px;
    margin-bottom: 20px; 
  }

  #section3 .sub4 .more {
    width: 100px;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 20px; 
  }

  #section3 .sub4 .head {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 5px;
    /* padding-bottom: 10px; */
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
   
  }
}

@media (min-width: 200px) and (max-width: 299px) {

  #section3 .sub4 {
    margin: 10px;
    /* border-top: 1px solid #fff; */
    padding-top: 10px;
  }

  #section3 .sub4 .bgImage {
    margin-left: 0%;
    margin-top: 0%;
  }

  #section3 .sub4 .bgImage img {
    width: 150px;
    height: 100px;
  }

  #section3 .sub1 .content2 p {
    font-size: 20px;
    font-weight: 600;
  }

  #section3 .sub2 .content1 img {
    height: 90px;
  }

  #section3 .sub4 .line3 {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  #section3 .sub3 .content1 p {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 25px !important;
  }

  #section3 .sub4 .leftContent {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    justify-content: center;
    width: 110px;
    margin-bottom: 20px;
  }

  #section3 .sub4 .more {
    width: 80px;
    margin-top: 8px;
    font-size: 9px;
    font-weight: 700;
    border-radius: 20px;
  }

  #section3 .sub4 .head {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }

  #section3 .sub4 .content {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
}

#isoSection {
  padding: 5%;
}

/* #isoSection .isoHead {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #000;
  font-size: 26px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
} */

#isoSection .isosubHead {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  margin-top: 20px;
}

@media (min-width: 200px) and (max-width: 900px) {
  #isoSection .isosubHead {   
    font-size: 20px;
   
  }
}

#isoSection .isosubHeadBlue {
  font-family: 'Red Hat Display', sans-serif !important;
  color: #615AE4;
  font-size: 22px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
}

@media (min-width: 200px) and (max-width: 900px) {
  #isoSection .isosubHeadBlue {   
    font-size: 20px;
   
  }
}

#isoSection .isotext {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000000;
  margin-top: 20px;
}

@media (min-width: 200px) and (max-width: 900px) {
  #isoSection .isotext {   
    font-size: 16px;
    line-height: 22px;
   
  }
}

#sliderSection {
  padding: 2%;
}

#sliderSection .tabName {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 18px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  text-transform: none;
}

@media (min-width:1000px) and (max-width:1100px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 15px !important;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:800px) and (max-width:1000px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 11px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:700px) and (max-width:800px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 10px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:600px) and (max-width:700px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 7px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:500px) and (max-width:600px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 6px;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:400px) and (max-width:500px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 8px;
    font-weight: 800;
    line-height: 7px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

@media (min-width:300px) and (max-width:400px) {
  #sliderSection .tabName {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 8px;
    font-weight: 800;
    line-height: 7px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-transform: none;
  }
}

#sliderSection .css-19kzrtu {
  padding-top: 1%;
}



#section5 .sub1 .content2 p {
  color: #000000;
  text-align: left;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 800;

}

#section5 .sub1 .content2 .viewmore {
  font-family: 'Red Hat Display', sans-serif;
  width: 140px;
  height: 38px;
  margin-right: 20px;
  text-transform: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background: #615AE4;
  border-radius: 25px;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #FFFFFF;
}

#section5 .sub1 .content2 .viewmore:hover {
  background: #FCB632;
}


#section5 .header {
  color: #000;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
}

#section5 .sub1 {
  margin-top: 0%;
  color: #000 !important;
}

#section5 .sub1 .cardtypo {
  font-family: 'Red Hat Display', sans-serif !important;

}

#section5 .sub1 p {
  color: #000;
}

#section5 .sub2 {
  margin-bottom: 0%;
}

#section5 .lower {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  height: 45px;
  background-color: #fff;

}

#section5 .lower:hover {
  background-color: #615AE4;
  /* Change to whatever color you desire */
  text-decoration: underline;
}


#section5 .sub2 .image-container {
  position: relative;
}

#section5 .sub2 .image-heading {
  position: absolute;
  top: 100px;
  left: 70px;
  /* transform: translate(-100px, 70px); */
  width: 24%;
  color: #fff;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 46px;
}

#section4 {
  margin-top: 2%;
  height: auto;
  /* background-color: #f7f7f7; */
}

#section4 .sub1 .c1,
.c2,
.c3 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#section4 .sub1 .c1 p {
  color: #000;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 26px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

}

@media (min-width:300px) and (max-width:700px) {
  #section4 .sub1 .c1 p  {    
    font-size: 20px;    
  }
}

#section4 .section4List {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 13px;
  font-weight: 400;
}

@media (min-width:300px) and (max-width:700px) {
  #section4 .section4List {    
    font-size: 13px;    
  }
}

#section4 .sub1 .c3 p {
  color: #000;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

@media (min-width:300px) and (max-width:700px) {
  #section4 .sub1 .c3 p  {    
    font-size: 17px;
    line-height: 28px;    
  }
}

#section4 .svg-line {
  width: 2px;
  height: 35px;
  background-color: transparent;
  position: relative;
}

#section4 .svg-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
  background-color: #000000;
}

#section4 .sub2 .line {
  height: 2px;
  width: 100%;
  background-color: #000000;
  margin-top: 20px;
}

#section4 .sub2 .c4 .row .col-sm-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#section4 .sub2 .c4 h6 {
  color: #000;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



#section4 .sub2 .c2 h5 {
  color: #000;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

#section4 .sub2 .c1 h5 {
  color: #000000;
  text-align: center;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@media (max-width: 1200px) {
  #section5 .sub2 .image-heading {
    position: absolute;
    top: 100px;
    left: 70px;
    /* transform: translate(-100px, 70px); */
    width: 34%;
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
  }
}

@media (max-width: 991px) {
  #section5 .sub2 .image-heading {
    position: absolute;
    top: 70px;
    left: 70px;
    /* transform: translate(-100px, 70px); */
    width: 60%;
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 40px;
  }

  #section5 .sub2 {
    margin-bottom: 10%;
  }
}

@media (max-width: 415px) {
  #section5 .sub2 .image-heading {
    position: absolute;
    top: 20px;
    left: 10px;
    /* transform: translate(-100px, 70px); */
    width: 90%;
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 26px;
  }

  #section5 .sub2 {
    margin-bottom: 10%;
  }
}

@media (max-width: 412px) {
  #section1 .content-overlay h1 {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
  }

  #section1 .content-overlay h6 {
    color: #ffffff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  #section3 .sub1 .content3 p {
    color: #ffffff;
    text-align: center;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 43px;
  }

  #section3 .sub3 .content1 p {
    color: #ffffff;
    text-align: center;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  /* 
  #section4 .sub1 .content3 p {
    color: #000;
    text-align: center;
     font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
  }
   */
}

@media (min-width: 200px) and (max-width: 380px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 23px !important;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media (min-width: 380px) and (max-width: 575px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 23px !important;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

@media (min-width: 1437px) and (max-width: 1600px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 1263px) and (max-width: 1437px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 1194px) and (max-width: 1262px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 1055px) and (max-width: 1193px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 576px) and (max-width: 1055px) {
  #section1 .content-overlay h6 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #section1 .content-overlay h5 {
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }

  #section1 .custom-button3 {
    padding: 8px !important;
    font-size: 18px !important;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    margin-left: 25px;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    margin-left: 18px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    margin-left: 12px;
  }
}

@media (min-width: 994px) and (max-width: 1199px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 894px) and (max-width: 994px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 794px) and (max-width: 894px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 694px) and (max-width: 794px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 593px) and (max-width: 694px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 576px) and (max-width: 593px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
  }

  #section1 .custom-button3 {
    padding: 8px !important;
    font-size: 18px !important;
  }

  #section1 .custom-button3 img {
    height: 30px !important;
    width: 30px !important;
  }
}

@media (min-width: 497px) and (max-width: 575px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 400px) and (max-width: 599px) {
  #section1 .background-video-container {
    margin-top: 80px !important;
  }
}

@media (min-width: 300px) and (max-width: 399px) {
  #section1 .background-video-container {
    margin-top: 100px !important;
  }
}

@media (min-width: 200px) and (max-width: 299px) {
  #section1 .background-video-container {
    margin-top: 100px !important;
  }
}

@media (min-width: 100px) and (max-width: 199px) {
  #section1 .background-video-container {
    margin-top: 150px !important;
  }
}

@media (min-width: 347px) and (max-width: 497px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
}

@media (min-width: 200px) and (max-width: 347px) {
  #section1 .content-overlay h1 {
    color: #ffcf24;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
  }

  #section1 .custom-button3 {
    padding: 8px !important;
    font-size: 18px !important;
  }

  #section1 .custom-button3 img {
    height: 30px !important;
    width: 30px !important;
  }

  #section1 .homebtn {
    top: 140px !important;
    left: 30px !important;
  }
}


#section6 .Head {
  font-family: 'Red Hat Display', sans-serif !important;
  width: 175px;
  font-size: 26px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

}

#section6 .innerGrid {
  display: block;
}

#section6 .Text {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin-top: 20px;
}

#section6 .innerGrid .images {
  display: flex;
  margin-top: 30px;
}

#section6 .sec7subHeadBlue {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 22px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #615AE4;
  /* white-space: nowrap; */
}

#section6 .innerGrid .images .playStore:hover,
#section6 .innerGrid .images .appStore:hover {
  transform: scale(0.8);
}

@media (max-width: 899px) {
  #section6 .innerGrid {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  #section6 .Head {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 22px;
  }

  #section6 .Text {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  #section6 .innerGrid .images {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
}

@media(max-width: 400px) {
 

  #section5 .sub1 .content2 .viewmore {
    width: 90px;
    height: 30px;
    margin-right: 20px;
    font-size: 11px;
    font-weight: 700;
  }
}

.cardTimeView{
  padding-left: 90px;
}

@media (min-width: 200px) and (max-width: 366px) {
  .cardTimeView{   
    padding-left: 40px;   
  }
}