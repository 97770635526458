#ss .sub1 .content2 p {
    color: #000000;
    text-align: left;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;  
  }
  
  #ss .sub1 .content2 .viewmore {
    font-family: 'Red Hat Display', sans-serif;
    width: 140px;
    height: 43px;
    margin-right: 20px;
    text-transform: none;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
    background: #615AE4;
    border-radius: 25px;
    color: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid #FFFFFF;
  }
  
  #ss .sub1 .content2 .viewmore:hover {
    background: #FCB632;
  }
  
  
  #ss .header {
    color: #000;
    text-align: center;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
  }
  
  #ss .sub1 {
    margin-top: 0%;
    color: #000 !important;
  }
  
  #ss .sub1 .cardtypo {
    font-family: 'Red Hat Display', sans-serif !important;
  
  }
  
  #ss .sub1 p {
    color: #000;
  }
  
  #ss .sub2 {
    margin-bottom: 0%;
  }
  
  #ss .lower {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    height: 45px;
    background-color: #fff;
  
  }
  
  #ss .lower:hover {
    background-color: #615AE4;
    /* Change to whatever color you desire */
    text-decoration: underline;
  }
  
  
  #ss .sub2 .image-container {
    position: relative;
  }
  
  #ss .sub2 .image-heading {
    position: absolute;
    top: 100px;
    left: 70px;
    /* transform: translate(-100px, 70px); */
    width: 24%;
    color: #fff;
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px;
  }


  @media (min-width: 400px) and (max-width: 599px) {
    #ss .sub1 .content2 p {
      color: #000000;
      text-align: left;
      font-family: 'Red Hat Display', sans-serif !important;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 800;  
    }
  }


  @media (min-width: 300px) and (max-width: 399px) {
    #ss .sub1 .content2 p {
      color: #000000;
      text-align: left;
      font-family: 'Red Hat Display', sans-serif !important;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 800;  
    }

    #ss .sub1 .content2 .viewmore {
      font-family: 'Red Hat Display', sans-serif;
      width: 120px;
      height: 43px;
      margin-right: 20px;
      text-transform: none;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0em;
      text-align: center;
      background: #615AE4;
      border-radius: 25px;
      color: white;
      box-shadow: 0px 4px 4px 0px #00000040;
      border: 1px solid #FFFFFF;
    }
  }

  @media (min-width: 200px) and (max-width: 299px) {

  }