@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');


.header .header-image img {
  height: 40px;
  margin-right: 10px;
}

.header .login {
  font-family: 'Red Hat Display', sans-serif;
  width: 100%;
  /* padding: 1%; */
  font-size: 16px;
  font-weight: 700 !important;
  letter-spacing: 0em;
  text-align: center;
  background: #615AE4;
  border-radius: 25px;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #FFFFFF
}

.mobileResLoginButton {
  font-family: 'Red Hat Display', sans-serif;
  width: 100%;
  /* padding: 1%; */
  font-size: 16px;
  font-weight: 700 !important;
  letter-spacing: 0em;
  text-align: center;
  background:#615AE4;
  border-radius: 0px !important;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #FFFFFF
}



.header .list-item-button {

  font-family: 'Red Hat Display', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: black;
  letter-spacing: 0em;
  text-align: center;

}

.header .login:hover , .header .list-item-button:hover {
  background: #FCB632;
  color: #FFFFFF;
}


.mobileMenuLine{
  height: 2px;
  width: 14px !important;
  background:#FFCF24;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 5px;
}

.mobileMenuText{
  font-family: 'Red Hat Display' !important;
  font-size: 16px;
  font-weight: 700 !important;
  color: #fff;
}



/* .custom-button1:hover { */
/* Remove the hover effect by setting the same styles as the default state */
/* color: #615AE4 !important; */
/* Set the background color to the default color */
/* Add any other styles you want to reset on hover here */
/* } */

/* .custom-button2:hover { */
/* Remove the hover effect by setting the same styles as the default state */
/* color: white !important; */
/* Set the background color to the default color */
/* Add any other styles you want to reset on hover here */
/* } */


@media (max-width: 1000px) {
  .header .list-item-button {
    font-size: 16px; /* Adjust the font size as per your requirement */
  }
  .header .login {
    font-size: 14px;
    font-weight: 700;
  }
  .header .header-image img {
    height: 30px;
    margin-right: 10px;
  }
}

@media (max-width: 900px) {
  .header .list-item-button {
    font-size: 14px; /* Adjust the font size as per your requirement */
  }
  .header .login {
    font-size: 14px;
    font-weight: 500;
  }
  .header .header-image img {
    height: 30px;
    margin-right: 10px;
  }
}

@media (max-width: 650px) {
  .header .list-item-button {
    font-size: 14px; /* Adjust the font size as per your requirement */
  }
  .header .login {
    font-size: 12px;
    font-weight: 700;
  }
  .header .header-image img {
    height: 29px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media (max-width: 500px) {
  .header .header-image img {
    height: 29px;
    margin-right: 0px;
    margin-left: 40px;
  }
}

@media (max-width: 400px) {
  .header .list-item-button {
    font-size: 12px; /* Adjust the font size as per your requirement */
  }
  .header .login {
    font-size: 12px;
    font-weight: 700;
  }
  .header .header-image img {
    height: 25px;
    margin-right: 0px;
    margin-left: 45px;
  }
}

/* @media (min-width: 600px) {
  .list-item-button:hover::before {
    width: 100%;
    height: 10%;
    color: #615AE4;
    content: "";
    position: absolute;
  }

  .list-item-button.active {
    &::before {
      width: 100%;
      height: 10%;
      color: #615AE4;
      content: "";
      position: absolute;
    }
  }
} */


@media (max-width :990px) {
  .sm-navbar {
    display: flex;
    justify-content: space-between;
  }
}

/* Navbar.css */

.navbar {
  position: fixed; /* Fix the navbar position */
  top: 0; /* Stick it to the top of the viewport */
  left: 0;
  right: 0;
  z-index: 999; /* Ensure it stays above other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
}


.navbar .navbar-logo img {
  height: 40px;
}

.navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-links a {
  color: #000000;
  text-decoration: none;
  margin: 0 10px;
}

.navbar-toggle {
  display: none; /* Hide toggle button by default */
}

.navbar-hamburger-menu {
  display: none; /* Hide hamburger menu by default */
}

/* Media query for small devices */
@media only screen and (max-width: 768px) {
  .navbar-links {
    display: none; /* Hide regular links on small devices */
  }

  .navbar-toggle {
    display: block; /* Display toggle button on small devices */
  }

  .navbar-hamburger-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    text-align: center;
  }

  .navbar-hamburger-menu a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
  }
}
