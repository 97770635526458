#contact .contactForm {
  background-image: url("../../assets/images/contactForm.png");
  flex-shrink: 0;
  background-size: cover;
}

#contact .homeForm {
  background-image: url("../../assets/images/homeFormImage.png");
  flex-shrink: 0;
  background-size: cover;
}

#contact .softwareForm {
  background-image: url("../../assets/images/homeFormImage.png");
  flex-shrink: 0;
  background-size: cover;
}

#contact .fleetForm {
  background-image: url("../../assets/images/fleetForm.png");
  flex-shrink: 0;
  background-size: cover;
  /* background-position: center;
  background-repeat: no-repeat; */
}


#contact .miForm {
  background-image: url("../../assets/images/miForm.png");
  flex-shrink: 0;
  background-size: cover;
}

#contact .mtForm {
  background-image: url("../../assets/images/mtForm.png");
  flex-shrink: 0;
  background-size: cover;
}

#contact .icForm {
  background-image: url("../../assets/images/icForm.png");
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

#contact .dwForm {
  background-image: url("../../assets/images/dwForm.png");
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

#contact .caForm {
  background-image: url("../../assets/images/caForm.png");
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

#contact .ccForm {
  background-image: url("../../assets/images/dwForm.png");
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

#contact .form-grid {
  background: #FAF6F0;
  border-radius: 10px;

}

#contact .form-grid .form-container {
  padding: 5%;
}

#contact .custom-snackbar {
  background: white;
}

#contact .form-grid .title {
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 24px !important;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
}

#contact .form-grid .formAgree {
  margin-top: 20px;
  font-family: 'Red Hat Display', sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

#contact .form-grid .formAgree .formLink {
  color: #615AE4;
  text-decoration: none;
}

#contact .form-grid .submit {
  font-family: 'Red Hat Display', sans-serif !important;
  width: 150px;
  margin-top: 3px;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  background: #FCB632;
  border-radius: 25px;
  color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #FFFFFF
}

#contact .form-grid .submit:hover {
  background: #615AE4;
}

.contactFeedbackModal {
  font-family: 'Red Hat Display', sans-serif !important;
}