@media (min-width: 300px) and (max-width: 900px) {
    #drivercoach .firstimage img {
      height: 250px !important;
      width: auto !important;
    }
    #drivercoach .secondimage img {
padding-left: 55px;
    }
  }

@media (min-width: 900px) and (max-width: 1000px) {
  #drivercoach .firstimage img {
    height: 250px !important;
    width: 100% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 12px !important;
    padding-top: 0 !important;
}
}

@media (min-width: 1000px) and (max-width: 1050px) {
  #drivercoach .firstimage img {
    height: 250px !important;
    width: 100% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 14px !important;
    padding-top: 0 !important;
}
}
@media (min-width: 1050px) and (max-width: 1150px) {
  #drivercoach .firstimage img {
    height: 280px !important;
    width: 100% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 14px !important;
    padding-top: 0 !important;
}
}

@media (min-width: 1150px) and (max-width: 1300px) {
  #drivercoach .firstimage img {
    height: 300px !important;
    width: 100% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 14px !important;
    padding-top: 0 !important;
}
}

@media (min-width: 1300px) and (max-width: 1400px) {
  #drivercoach .firstimage img {
    height: 336px !important;
    width: 100% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 15px !important;
}
}

@media (min-width: 1400px) and (max-width: 1500px) {
  #drivercoach .firstimage img {
    height: 360px !important;
    width: 103% !important;
  }
  #drivercoach .driverCoachDesc{
    font-size: 16px !important;
}
}

@media (min-width: 1500px) and (max-width: 1600px) {
  #drivercoach .firstimage img {
    height: 336px !important;
    width: 80% !important;
  }
  
#drivercoach .driverCoachDesc{
    font-size: 16px !important;
}
}

@media (min-width: 1600px) and (max-width: 1700px) {
  #drivercoach .firstimage img {
    height: 336px !important;
    width: 80% !important;
  }
  
#drivercoach .driverCoachDesc{
    font-size: 18px !important;
}
}

@media (min-width: 1700px) and (max-width: 1800px) {
  #drivercoach .firstimage img {
    height: 336px !important;
    width: 75% !important;
  }
  
#drivercoach .driverCoachDesc{
    font-size: 18px !important;
}
}

@media (min-width: 1800px) and (max-width: 2000px) {
  #drivercoach .firstimage img {
    height: 336px !important;
    width: 60% !important;
  }

  
#drivercoach .driverCoachDesc{
    font-size: 18px !important;
}
}

