#swiper .line {
  height: 2px;
  width: 250px;
  background-color: #ffcf24;
  margin-bottom: 10px;
}

#swiper .content1 {
  color: #000;
  text-align: center;
   font-family: 'Red Hat Display', sans-serif !important;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
}

#swiper .content2 {
  color: #000;
  text-align: center;
   font-family: 'Red Hat Display', sans-serif !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-top: 0%;
}

#swiper .content3 {
  color: #000;
  text-align: center;
   font-family: 'Red Hat Display', sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

@media (max-width: 770px) {
  #swiper .content1 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  #swiper .content2 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  #swiper .content3 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

@media (max-width: 470px) {
  #swiper .content1 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  #swiper .content2 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  #swiper .content3 {
    color: #000;
    text-align: center;
     font-family: 'Red Hat Display', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

