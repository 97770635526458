/* .banner {
 
  background: linear-gradient(90deg, #000 16.15%, #000 64.06%, #af7500 100%);
  
  
  color: white;

} */
.bannerTitle{
  font-family:  system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 1.7rem!important;
  font-style: normal;
  font-weight: 100!important;
}

