
#softwarel .head{
    color: #000;
    text-align: center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

#section1 .background-video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 95.3vh;
  z-index: -1;
}


@media (min-width: 1800px) and (max-width: 2000px) {
    #softwarel img{
  height: 400px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1700px) and (max-width: 1800px) {
    #softwarel img{
  height: 370px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1600px) and (max-width: 1700px) {
    #softwarel img{
  height: 350px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1500px) and (max-width: 1600px) {
    #softwarel img{
  height: 330px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1400px) and (max-width: 1500px) {
    #softwarel img{
  height: 300px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1300px) and (max-width: 1400px) {
    #softwarel img{
  height: 280px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1200px) and (max-width: 1300px) {
    #softwarel img{
  height: 260px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1100px) and (max-width: 1200px) {
    #softwarel img{
  height: 240px !important;
  width: 100% !important;
    }
  }
@media (min-width: 1000px) and (max-width: 1100px) {
    #softwarel img{
  height: 220px !important;
  width: 100% !important;
    }
  }
@media (min-width: 900px) and (max-width: 1000px) {
    #softwarel img{
  height: 200px !important;
  width: 100% !important;
    }
  }
@media (min-width: 800px) and (max-width: 900px) {
    #softwarel img{
  height: 180px !important;
  width: 100% !important;
    }
  }
@media (min-width: 600px) and (max-width: 800px) {
    #softwarel img{
  height: 160px !important;
  width: 100% !important;
    }
  }
@media (min-width: 500px) and (max-width: 599px) {
    #softwarel img{
  height: 300px !important;
  width: 80% !important;
  margin-left: 60px;
    }
    .graphText{
      left: 15% !important;
    }
  }
@media (min-width: 400px) and (max-width: 500px) {
    #softwarel img{
  height: 280px !important;
  width: 100% !important;
  margin-left: 10px;
    }
    .graphText{
      position: "absolute";
      left: 5% !important;
    }
  }
@media (min-width: 300px) and (max-width: 400px) {
    #softwarel img{
  height: 222px !important;
  width: 100% !important;
  margin-left: 10px;
    }
  }

  @media (min-width: 400px) and (max-width: 300px) {
    #softwarel img{
  height: 210px !important;
  width: 100% !important;
  margin-left: 10px;
    }
    .graphText{
      position: "absolute";
      left: 8% !important;
    }
  }