.motorIcontitle {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-weight: 700 !important;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    color: #000000;
}

.motorSubtitleNew {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}