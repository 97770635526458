* {
  margin: 0;
  padding: 0;
}

.solutions-container{
  max-width: 100%;
  text-align: center;    
}
.solutions-title :first-child {
  color: #FCAD0D;
  font-family: Segoe UI;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

/* solutions.css */
.filter-option {
cursor: pointer;
}

.line {
  height: 2px;
  width: 250px;
  background-color: #ffcf24;
  margin-bottom: 20px;
}

.active-filter {
color: #FCAD0D; /* Set your active color here */
}

.MuiPaginationItem-outlined.MuiPaginationItem-page.Mui-selected {
  color: orange !important;
  border-color: white !important;
}

/* Override color for the first pagination number */
.MuiPaginationItem-page.Mui-selected {
  color: orange !important;
  background-color: transparent !important;
}

/* Override color for other pagination numbers */
.MuiPaginationItem-page:not(.Mui-selected) {
  color: black !important;
  font-weight: 600;
}

/* Target the root element of Pagination */
.custom-pagination-root .MuiPaginationItem-icon {
  color: orange;
  border: 1px solid;
  border-color: orange !important;
  border-radius: 50%;
  font-size: 2.5rem;

}

@media screen and (max-width: 422px) {
.solutions-title .MuiButtonBase-root {
  font-size: 14px !important; /* Adjust font size */
  padding: 8px; /* Adjust padding */
  border-right-width: 1px; /* Adjust border thickness */
}


}
@media screen and (max-width: 364px) {
.solutions-title .MuiButtonBase-root {
  font-size: 12px !important; /* Adjust font size */
  padding: 8px; /* Adjust padding */
  border-right-width: 1px; /* Adjust border thickness */
}


}

@media screen and (max-width: 334px) {
.solutions-title .MuiButtonBase-root {
  font-size: 11px !important; /* Adjust font size */
  padding: 8px; /* Adjust padding */
  border-right-width: 1px; /* Adjust border thickness */
}


}
@media (min-width: 1200px){

.css-1oqqzyl-MuiContainer-root {
     max-width: 2000px!important; 
}
}

