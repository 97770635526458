.softwareHero {
  display: flex;
  background: url('../../../../assets/images/softmain.png') no-repeat;
  background-size: cover;
  background-position: right;
}

@media (min-width: 160px) and (max-width: 609px) {
  .softwareHero {
    display: flex;
    background: url('../../../../assets/images/softwaremobilebanner.png') no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.gridContainer {
  height: 100%;
}


.heroimg2:hover, .heroimg3:hover{
  transform: scale(0.8);
}

@media (min-width: 1360px) and (max-width: 2500px) {
  .softwareHero .heroimg {
    height: 600px;
    padding: 42px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 160px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
  .softwareHero .herosubtext {
    margin-top: 80px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 60px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: -20px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 1250px) and (max-width: 1360px) {
  .softwareHero .heroimg {
    height: 550px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 160px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
  .softwareHero .herosubtext {
    margin-top: 70px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 40px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: -10px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 1150px) and (max-width: 1250px) {
  .softwareHero .heroimg {
    height: 500px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 130px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
  .softwareHero .herosubtext {
    margin-top: 50px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 50px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: 10px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 1050px) and (max-width: 1150px) {
  .softwareHero .heroimg {
    height: 470px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 130px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .softwareHero .herosubtext {
    margin-top: 60px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 50px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 40px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 40px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 1000px) and (max-width: 1050px) {
  .softwareHero .heroimg {
    height: 430px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 110px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .softwareHero .herosubtext {
    margin-top: 50px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 30px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 40px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 40px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 900px) and (max-width: 1000px) {
  .softwareHero .heroimg {
    height: 400px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 100px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .softwareHero .herosubtext {
    margin-top: 40px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 30px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: -20px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .softwareHero .heroimg {
    height: 350px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 100px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }
  .softwareHero .herosubtext {
    margin-top: 50px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 20px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: -10px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .softwareHero .heroimg {
    height: 300px;
    padding: 40px;
    margin-top: 43px;
    margin-bottom: 43px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 100px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  }
  .softwareHero .herosubtext {
    margin-top: 30px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 10px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 30px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: -15px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}

@media (min-width: 450px) and (max-width: 700px) {
  .softmain {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .softwareHero .heroimg {
    height: 400px;
    padding: 40px;
    margin-bottom: 30px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 90px;
    margin-left: 30px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herosubtext {
    margin-top: 30px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
  }
  .softwareHero .herobottomimage {
    margin-top: 10px;
  }

  .softwareHero .herobottomimage .heroimg2 img {
    height: 28px;
  }

  .softwareHero .herobottomimage .heroimg3 img {
    height: 28px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: 10px;
  }
}

@media (min-width: 100px) and (max-width: 450px) {
  .softmain {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .softwareHero .heroimg {
    height: 420px;
    padding: 35px;
    margin-left: 0px;
  }

  .softwareHero .sub2 .main {
    display: flex;
    flex-direction: column;
  }

  .softwareHero .sub2 .main img {
    height: 50px;
  }

  .softwareHero .sub2 {
    margin-top: 90px;
    margin-left: 30px;
  }

  .softwareHero .herotext p {
    color: #fff;
    font-family: "Segoe UI";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
  }
  .softwareHero .herosubtext {
    margin-top: 20px;
    color: #fff;
    font-family: "Segoe UI";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    width: 250px;
    line-height: 23px;
  }
  .softwareHero .herobottomimage {
    margin-top: 10px;
  }


  .softwareHero .herobottomimage .heroimg2 img {
    height: 40px;
    margin-top: 10px;
  }
  .softwareHero .herobottomimage .heroimg3 img {
    height: 40px;
    margin-top: 10px;
  }

  .softwareHero .herobottomimage .heroimg3 img {
    margin-left: 20px;
  }
  .heroimg2:hover, .heroimg3:hover{
    transform: scale(0.8);
  }
}
