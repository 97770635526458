.footer-image {
  width: 11.875rem;
  height: auto;
  flex-shrink: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer_bg {
  background: url(../../../assets/images/footer2.png) no-repeat;
  background-size: cover;
  background-color: black;
  background-position: bottom;
}

.socialLink {
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  background-color: transparent;
  border-radius: 15%; /* optional: makes the background circular */
  padding: 2px; /* optional: adds some padding around the icon */
}

.socialLink:hover {
  transform: scale(1.2);
  background: #ffcf24;
}
.footerText {
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Red Hat Display';
}

.footerText:hover {
  /* transform: scale(1.2);  */
  color: #ffcf24;
}

.footerLastText {
  color: "white";
  text-decoration: "none";
  font-size: 13px;
  font-family: "Red Hat Display";
}

.footerLastText:hover {
  color: #ffcf24;
}

@media (max-width: 900px) {
  .footerDetail {
    padding-left: 0 !important;
  }
  .footer_bg ul > li,
  .footer-links {
    justify-content: center;
    text-align: center !important;
    padding-left: 0 !important;
  }
  .footerItem {
    padding-left: 0 !important;
    margin-top: 20px !important;
  }
  .footer-image-div {
    display: flex !important;
    justify-content: center !important;
  }
  .socialMedia {
    text-align: center;
    flex-direction: column;
  }
  .socialMediaLinks {
    justify-content: center;
  }
  .getInTouch {
    margin: 0 auto;
  }
}

#footer .foot-link a {
  text-decoration: none;
  color: white;
}

#footer .foot-link a:hover {
  color: #ffcf24;
}

#footer li {
  padding-left: 0 !important;
}

.socialMediaLinks {
  display: flex;
  gap: 10px;
}

.socialMediaLinks a {
  text-decoration: none;
  color: white;
  border-right: 1px solid #ffffff;
  padding-right: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.socialMediaLinks a:last-child {
  border-right: none;
}

@media (min-width: 100px) and (max-width: 899px) {
  .footer_main {
    display: flex;
    flex-direction: column-reverse !important;
    align-items: center;
  }
  .privlogo {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}
