.scroll-to-top-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: orange;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
  }
  
  .scroll-to-top-button:hover {
    background-color: #615AE4; /* Define hover effect */
  }