.whatWeProvideTitle{
    color: #000;
font-family: 'Red Hat Display';
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 55px
}

.whatWeProvideTitleDesc{
    color: #000;
font-family: 'Red Hat Display';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 26px;
text-align: left;
}

.whatweprovidebg{
    background:  linear-gradient(180deg, #100919 0%, #130922 100%);
}