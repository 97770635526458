/* .topLine {
  height: 2px;
  width: 250px;
  background-color: #ffcf24;
  margin-bottom: 20px;
  } */


  .heading .title {
    color: #000;
    text-align: center;
     font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 1rem;
  }
  
  .heading .sub-title {
      color: #FCAD0D;
      text-align: center;
       font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.75rem;    
  }
  