#fleet .imgGrid1 {
    background-image: url("../../../assets/images/fleet1.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .imgGrid2 {
    background-image: url("../../../assets/images/fleet2.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .imgGrid2-1 {
    background-image: url("../../../assets/images/fleet2.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .mi1 {
    background-image: url("../../../assets/images/mi1.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .mt1 {
    background-image: url("../../../assets/images/mt1.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .mt2 {
    background-image: url("../../../assets/images/mt2.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .ic1 {
    background-image: url("../../../assets/images/insurancevechicle.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .ic2 {
    background-image: url("../../../assets/images/insuranceradar.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .ic3 {
    background-image: url("../../../assets/images/ic3.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .ic4 {
    background-image: url("../../../assets/images/ic4.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .dw1 {
    background-image: url("../../../assets/images/dw1.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .dw2 {
    background-image: url("../../../assets/images/dw2.png");
    flex-shrink: 0;
    background-size: cover;
}

#fleet .icontitle {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    color: black;
}

#fleet .iconsubtitle {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
}

#fleet .subBg {
    background: linear-gradient(180deg, #100919 0%, #130922 100%);
    height: 100%;
    padding: 0px;
}

#fleet .subBg .sub4 {
    padding: 45px;
    padding-bottom: 0px;
}

#fleet .sub4 .leftContent {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 26px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: left;
    width: 210px;
}

#fleet .sub4 .more {
    font-family: 'Red Hat Display', sans-serif;
    width: 150px;
    margin-top: 50px;
    font-size: 16px;
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0em;
    text-align: center;
    background: #615AE4;
    border-radius: 25px;
    color: white;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid #FFFFFF
}

#fleet .sub4 .more:hover {
    background: #FCB632;
}

#fleet .sub4 .bgImage {
    position: "absolute";
    bottom: 0;
    left: 0;
    margin-left: 5%;
    margin-top: -10%;
}

#fleet .sub4 .bgImage img {
    width: 690px;
    height: 480px;

}

#fleet .sub4 .line3 {
    height: 1px;
    width: 100%;
    background-color: #fff;
    margin-top: 40px;
    margin-bottom: 30px;
}

#fleet .sub4 .head {
    font-family: 'Red Hat Display', sans-serif !important;
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
}

.content {
    margin-top: 0px !important;
    line-height: 20px;
}

#fleet .sub4 .content {
    font-family: 'Red Hat Display', sans-serif !important;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

#fleet .solution3-paragraph div {
    font-family: 'Red Hat Display', sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

@media (min-width: 1501px) and (max-width: 1600px) {
    #fleet .sub4 .bgImage {
        margin-left: 5%;
        margin-top: -20%;
    }

    #fleet .sub4 .bgImage img {
        width: 650px;
        height: 450px;
    }
}

@media (min-width: 1246px) and (max-width: 1309px) {

    #fleet .iconsubtitle {
        font-family: 'Red Hat Display', sans-serif !important;
        font-size: 17px !important;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
    }
}

@media (min-width: 1401px) and (max-width: 1500px) {
    #fleet .sub4 .bgImage {
        margin-left: 3%;
        margin-top: -15%;
    }

    #fleet .sub4 .bgImage img {
        width: 630px;
        height: 450px;
    }

    #fleet .sub4 .line3 {
        margin-top: 35px;
        margin-bottom: 25px;
    }
}

@media (min-width: 1301px) and (max-width: 1400px) {
    #fleet .sub4 .bgImage {
        margin-left: 2%;
        margin-top: -15%;
    }

    #fleet .sub4 .bgImage img {
        width: 600px;
        height: 430px;
    }

    #fleet .sub4 .line3 {
        margin-top: 35px;
        margin-bottom: 25px;
    }
}

@media (min-width: 1101px) and (max-width: 1300px) {
    #fleet .sub4 .bgImage {
        margin-left: 0%;
        margin-top: -15%;
    }

    #fleet .sub4 .bgImage img {
        width: 500px;
        height: 390px;
    }

    #fleet .sub4 .line3 {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    #fleet .sub4 .leftContent {
        font-size: 22px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        width: 180px;
    }

    #fleet .sub4 .more {
        width: 140px;
        margin-top: 20px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
    }

    #fleet .sub4 .content {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
    }
}

@media (min-width: 901px) and (max-width: 1100px) {
    #fleet .sub4 .bgImage {
        margin-left: 0%;
        margin-top: -20%;
    }

    #fleet .sub4 .bgImage img {
        width: 430px;
        height: 320px;
    }

    #fleet .sub4 .line3 {
        margin-top: 25px;
        margin-bottom: 15px;
    }

    #fleet .sub4 .leftContent {
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        width: 150px;
    }

    #fleet .sub4 .more {
        width: 110px;
        margin-top: 10px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
    }

    #fleet .sub4 .content {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
    }
}

@media (min-width: 701px) and (max-width: 900px) {


    #fleet .sub4 .bgImage {
        margin-left: 0%;
        margin-top: -15%;
    }

    #fleet .sub4 .bgImage img {
        width: 310px;
        height: 200px;
    }

    #fleet .sub4 .line3 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    #fleet .sub4 .leftContent {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        width: 120px;
    }

    #fleet .sub4 .more {
        width: 90px;
        margin-top: 10px;
        font-size: 9px;
        font-weight: 700;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
    }

    #fleet .sub4 .content {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
    }
}

@media (min-width: 600px) and (max-width: 700px) {


    #fleet .sub4 .bgImage {
        margin-left: 0%;
        margin-top: -15%;
    }

    #fleet .sub4 .bgImage img {
        width: 270px;
        height: 170px;
    }

    #fleet .sub4 .line3 {
        margin-top: 15px;
        margin-bottom: 8px;
    }

    #fleet .sub4 .leftContent {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        width: 110px;
    }

    #fleet .sub4 .more {
        width: 80px;
        margin-top: 8px;
        font-size: 10px;
        font-weight: 700;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }

    #fleet .sub4 .content {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
    }
}

@media (min-width: 400px) and (max-width: 599px) {

    #fleet .sub4 {
        padding-top: 20px;
        border-top: 1px solid #fff;
        margin-top: 10px;
    }

    #fleet .sub4 .bgImage {
        margin-left: 20%;
        margin-top: 0%;
    }

    #fleet .sub4 .bgImage img {
        width: 250px;
        height: 150px;
    }

    #fleet .sub4 .line3 {
        margin-top: 12px;
        margin-bottom: 8px;
        position: relative;
        left: -10px;
    }

    #fleet .sub4 .leftContent {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        justify-content: center;
        width: 110px;
        margin-bottom: 20px;
        position: relative;
        left: -10px;
    }

    #fleet .sub4 .more {
        width: 80px;
        margin-top: 10px;
        font-size: 10px;
        font-weight: 700;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        position: relative;
        left: -10px;
    }

    #fleet .sub4 .content {
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        position: relative;
        left: -10px;
    }

    #fleet .imgGrid2-1 {
        background-image: url("../../../assets/images/fleetmanagementMobile.png");
        flex-shrink: 0;
        background-size: cover;
    }

    #fleet .iconsubtitle {
        font-family: 'Red Hat Display', sans-serif !important;
        font-size: 16px !important;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
    }
}

@media (min-width: 300px) and (max-width: 399px) {

    #fleet .sub4 {
        margin: 10px;
        border-top: 1px solid #fff;
        padding-top: 10px;
    }

    #fleet .sub4 .bgImage {
        margin-left: 10%;
        margin-top: 4%;
    }

    #fleet .sub4 .bgImage img {
        width: 200px;
        height: 100px;
    }

    #fleet .sub4 .line3 {
        margin-top: 12px;
        margin-bottom: 8px;
        position: relative;
        left: -20px;
    }

    #fleet .sub4 .leftContent {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        justify-content: center;
        width: 110px;
        margin-bottom: 20px;
        position: relative;
        left: -20px;
    }

    #fleet .sub4 .more {
        width: 80px;
        margin-top: 8px;
        font-size: 10px;
        font-weight: 600;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        position: relative;
        left: -20px;
    }

    #fleet .sub4 .content {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        position: relative;
        left: -20px;
    }

    #fleet .imgGrid2-1 {
        background-image: url("../../../assets/images/fleetmanagementMobile.png");
        flex-shrink: 0;
        background-position: center;
    }

    #fleet .iconsubtitle {
        font-family: 'Red Hat Display', sans-serif !important;
        font-size: 16px !important;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: black;
    }
}

@media (min-width: 200px) and (max-width: 299px) {

    #fleet .sub4 {
        margin: 10px;
        border-top: 1px solid #fff;
        padding-top: 10px;
    }

    #fleet .sub4 .bgImage {
        margin-left: 0%;
        margin-top: 0%;
    }

    #fleet .sub4 .bgImage img {
        width: 150px;
        height: 100px;
    }

    #fleet .sub4 .line3 {
        margin-top: 12px;
        margin-bottom: 8px;

    }

    #fleet .sub4 .leftContent {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        justify-content: center;
        width: 110px;
        margin-bottom: 20px;
    }

    #fleet .sub4 .more {
        width: 80px;
        margin-top: 8px;
        font-size: 10px;
        font-weight: 600;
        border-radius: 20px;
    }

    #fleet .sub4 .head {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }

    #fleet .sub4 .content {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
    }

    #fleet .imgGrid2-1 {
        background-image: url("../../../assets/images/fleetmanagementMobile.png");
        flex-shrink: 0;
        background-position: center;
    }
}