
.workImg{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 3rem 0;
}
.workImgFluid{
    max-width: 100%;
}

.ruduLogo{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ruduImg{
    min-width: 200px;
    max-width: 35%;
}

.play{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top:70%;
}